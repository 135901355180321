import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.section`
  margin: 0 auto auto;
  width: 100%;
  max-width: ${props => props.theme.sizes.maxWidth};
  padding: 3em 1.5em 2em;
  flex-grow: 1;
`

const Text = styled.p`
  text-align: left;
  line-height: 1.6;
  a {
    color: #121212;
  }
`

const Terms = styled.h2`
  text-align: center;
  line-height: 1.6;
  font-weight: 600;
  font-size: 1.5em;
  a {
    color: #121212;
  }
`

const Label = styled.h3`
  line-height: 1.6;
  font-weight: 600;
  font-size: 1em;
  a {
    color: #121212;
  }
`


const ConsentText = props => {
  return (
    <Wrapper>
      <Text>
        <ol>
          <li><Label>1. Fee Payment</Label> The amount of half the purchased package is required as a deposit to book your Ashleigh Handzik Session. This fee reserves your date and time. All packages fees includes a pre-session consultation via phone or email to cover details in advance, 15- 20 minutes guaranteed time and talent in her location of choice, and retouching of agreed number of images from package of choice. The session fee is non-refundable and includes up to five people. If the deposit fee has not been received prior to the session, the session will be considered canceled and the slot will be offered to other clients. If the entire payment is not received by day of Mini Session, no photos will be released until payment received.</li>
          <li><Label>2. Inclement Weather</Label> If inclement weather or other adverse conditions prevent the session from meeting the photographer’s artistic standards she may elect to reschedule.</li>
          <li><Label>3. Failure to Perform</Label> If the Photographer is unable to perform to the guidelines of this contract due to a fire or other casualty, act of God, act of terrorism, or other cause beyond control of the parties, or due to a grave illness of the Photographer, and if the Photographer is unable to provide another photographer that is agreeable to the client, then all fees paid by the client will be returned in full. Ashleigh Handzik Photography shall have no further liability with respect to the agreement. If Ashleigh Handzik Photography is unable to perform to the obligations in this contract for reasons outside the sole control of Ashleigh Handzik, she will not be liable for costs in excess of the amount the client has paid. This limitation of liability will also apply in the event that negatives or digital images are lost through camera malfunction, damaged in processing, lost in the mail or otherwise damaged or lost without fault of the photographer, Ashleigh Handzik.</li>
          <li><Label>4. Shooting Time</Label> The photography schedule and selected methodology are designed to accomplish the goals and wishes of the Client in a manner enjoyed by all parties. Client and Ashleigh Handzik Photography agree that cheerful cooperation and punctuality are therefore essential to that purpose. Shooting commences at the scheduled start time. In the event that the client is late to the appointment set, the client’s session will still be fore the same scheduled ending time.</li>
          <li><Label>5. Image Files (Negatives)</Label> All ordered images are kept on file for 12 months. Images not ordered are purged one month past your session date. Please note that print and product prices are subject to change without any prior notice.</li>
          <li><Label>6. Exclusive Photographer</Label> The photographer will be the exclusive photographer with no other camera or recording devices permitted during your session. This includes other family or friends.</li>
          <li><Label>7. Viewing the Images</Label> The agreed amount of images will be processed and ready to view between one to two weeks after your session via an online gallery. Your purchased prints will be ready for delivery two to three weeks after payment has been received and cleared. Please allow four to six weeks for any custom items such as books and canvas prints. All images are to be inspected by you upon delivery. After this, all sales are final. There are no returns on prints, storyboards or albums, no exceptions.</li>
          <li><Label>8. Copyright</Label> Unless the purchase of digital files is made, the photographs produced by Ashleigh Handzik Photography are protected by Federal Copyright Law (all rights reserved) and may not be reproduced in any manner without Ashleigh Handzik Photography’s explicitly written permission. Upon purchase of the digital files by the client, limited copyright ownership of the resulting images will be transferred to the client under the following conditions: The digital negatives or prints are the property of Client for personal use and for the purposes of the reproduction and giving of photographs to friends and relatives and not to be used by the client for profit.</li>
          <li><Label>9. Digital Files</Label> Upon receipt of a downloaded images, the Client accepts all responsibility for archiving and protecting the photographs. A USB may be purchased at the current list price and will be available within two to four weeks. Ashleigh Handzik Photography is not responsible for the lifespan of any digital media provided or for any future changes in digital technology or media readers that might result in an inability to read discs provided. It is Client’s responsibility to make sure that digital files are copied to new media as required.</li>
          <li><Label>10. Model Release</Label> I hereby give Ashleigh Handzik Photography the irrevocable right to use my name, picture, portrait, or photograph in all forms and in all media and in all manners, without any restriction as to changes or alterations for advertising, trade, promotion, exhibition, or any other lawful purposes, and I waive any right to inspect or approve the photograph(s) or finished version(s) incorporating the photograph(s), including written copy that may be created and appear in connection therewith.</li>
          <li><Label>11. Print Release</Label> Ashleigh Handzik of Ashleigh Handzik Photography, am the rightful copyright owner of these digital images. I authorize the owner of these images the right to print these files. Original copyright remains with the photographer. The photos may be printed but not altered or edited in any way other than by the photographer and alterations is a violation of the copyright. Ashleigh Rouse is not liable for print quality, cropping or coloring of any photos not printed directly through my professional lab. Photo quality is only guaranteed through photos purchased directly through me and my lab. Proper recognition of Ashleigh Handzik Photography is appreciated and requested when publishing photos and using them online (example-like when putting them on Facebook). Photos may not be entered into any contest without my prior consent and without proper credit to Ashleigh Handzik Photography.</li>
        </ol>
      </Text>
    </Wrapper>
  );
}

export default ConsentText
