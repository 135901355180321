const theme = {
  global: {
    colors: {
      icon: '#666666',
    }
  },
  colors: {
    base: '#f4b0b0',
    secondary: '#E5E7E1',
    tertiary: '#f3f3f3',
    highlight: '#dbbaa1',
    underline: '#ffffff',
  },
  sizes: {
    maxWidth: '1050px',
    maxWidthCentered: '650px',
  },
  icon: {
    size: {
      small: '12px',
      medium: '24px',
      large: '48px',
      xlarge: '96px',
    },
  },
  responsive: {
    small: '35em',
    medium: '50em',
    large: '70em',
  },
}

export default theme
