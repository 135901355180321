import React from 'react'
import Helmet from 'react-helmet'
import config from '../utils/siteConfig'
import Layout from '../components/Layout'
import Container from '../components/Container'
import PageTitle from '../components/PageTitle'
import ConsentForm from '../components/ConsentForm'
import ConsentText from '../components/ConsentText'
import SEO from '../components/SEO'

const Consent = ({ data }) => {
  const postNode = {
    title: `Contact - ${config.siteTitle}`,
  }

  return (
    <Layout>
      <Helmet>
        <title>{`Consent Form - ${config.siteTitle}`}</title>
      </Helmet>
      <SEO postNode={postNode} pagePath="consent" customTitle />

      <Container>
        <PageTitle>Online Consent Form</PageTitle>
        <hr></hr>
        <br></br>
        <ConsentText />
        <ConsentForm />
      </Container>
    </Layout>
  )
}

export default Consent
